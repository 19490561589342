<template>
    <v-container fluid>
        <v-layout wrap>
            <v-flex fluid>
                <v-card outlined>
                    <v-card-title class="px-3">
                        <p class="font-weight-black">
                            Dados Pessoais
                        </p>
                    </v-card-title>
                    <v-card>
                        <v-col>
                            <v-row>
                                <v-textarea class="mx-1" rows="1" outlined dense value="" readonly label="Nome Completo"></v-textarea>
                                <v-textarea class="mx-1" rows="1" outlined dense value="" readonly label="Data de Nascimento"></v-textarea>
                                <v-textarea class="mx-1" rows="1" outlined dense value="" readonly label="CPF"></v-textarea>
                                <v-textarea class="mx-1" rows="1" outlined dense value="" readonly label="RG"></v-textarea>
                                <v-textarea class="mx-1" rows="1" outlined dense value="" readonly label="Espacialidade"></v-textarea>
                                <v-textarea class="mx-1" rows="1" outlined dense value="" readonly label="Conselho"></v-textarea>
                                <v-textarea class="mx-1" rows="1" outlined dense value="" readonly label="Nº Conselho"></v-textarea>
                                <v-textarea class="mx-1" rows="1" outlined dense value="" readonly label="UF do Conselho"></v-textarea>
                                <v-textarea class="mx-1" rows="1" outlined dense value="" readonly label="Endereço: Rua"></v-textarea>
                                <v-textarea class="mx-1" rows="1" outlined dense value="" readonly label="Nº"></v-textarea>
                                <v-textarea class="mx-1" rows="1" outlined dense value="" readonly label="Bairro"></v-textarea>
                                <v-textarea class="mx-1" rows="1" outlined dense value="" readonly label="Cidade"></v-textarea>
                                <v-textarea class="mx-1" rows="1" outlined dense value="" readonly label="UF"></v-textarea>
                                <v-textarea class="mx-1" rows="1" outlined dense value="" readonly label="Dados Bancários: PF ou PJ"></v-textarea>
                                <v-textarea class="mx-1" rows="1" outlined dense value="" readonly label="Banco"></v-textarea>
                                <v-textarea class="mx-1" rows="1" outlined dense value="" readonly label="AG:"></v-textarea>
                                <v-textarea class="mx-1" rows="1" outlined dense value="" readonly label="CC ou CP"></v-textarea>
                                <v-textarea class="mx-1" rows="1" outlined dense value="" readonly label="Conta:"></v-textarea>
                                <v-textarea class="mx-1" rows="1" outlined dense value="" readonly label="PIX"></v-textarea>
                            </v-row>
                        </v-col>
                    </v-card>
                </v-card>
            </v-flex>
        </v-layout>
    </v-container>
</template>
<script>
export default {
    name: "dadospessoais_equipe_multi",
}
</script>